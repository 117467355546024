import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "create--add-events"
    }}>{`Create & Add Events`}</h1>
    <p>{`If you're adding a new event that's similar or the same as one you've hosted before, you can clone the information from the old event and create a new one.`}</p>
    <h2 {...{
      "id": "cloning-events"
    }}>{`Cloning Events`}</h2>
    <ol>
      <li parentName="ol">{`Select the event you'd like to copy`}</li>
      <li parentName="ol">{`Select 'Clone event'`}</li>
      <li parentName="ol">{`Check the event details and make any changes needed`}</li>
      <li parentName="ol">{`Add an event date and time`}</li>
      <li parentName="ol">{`Submit event`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/JaTnCYW6eqs" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">This saves lots of time when creating events that happen often around your library service.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      